<template>
  <div>
    <img src="@/assets/images/UMAI.svg"> <!--<img src="@/assets/images/umai-small-black.png" /> -->
  </div>
</template>

<script>

export default {
  name: 'SiteNav',
  data: () =>  ({
    items: [],
    name: 'map',
  }),
  computed: {
    isAdmin () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='administrator'
      else return false
    },
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isDealer () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Дилер'
      else return false
    },
    isSupplier () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Поставщик'
      else return false
    },
    isTerminals () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.terminals
      else return false
    },
    isTerminal () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='terminal'
      else return false
    },
    isCollector () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='collector'
      else return false
    }
  },
}
</script>

<style scoped>
img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50%;
  min-width: 120px;
  max-width:500px;
  z-index: -1;
}
</style>
